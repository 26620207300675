<template>
  <div class="product-manual">
    <div class="product-manual-list">
      <!-- <img src="../assets/images/L20Pro.png" class="product-img" alt="" @click="l20ProImgClick" />
      <img src="../assets/images/L20Boost.png" class="product-img" alt="" @click="l20BoostImgClick" />
      <img src="../assets/images/N1Pro.png" class="product-img" alt="" @click="n1ProImgClick" />
      <img src="../assets/images/N1Air.png" class="product-img" alt="" @click="n1AirImgClick" />
      <img src="../assets/images/N1AirST.png" class="product-img" alt="" @click="n1AirSTImgClick" /> -->
      <img src="../assets/images/cargo.png" class="product-img" alt="" @click="cargoImgClick" />
    </div>
    <div id="zoomBox" class="product-manual-pdf" v-if="pdfUrl">
      <pdf ref="pdf" v-for="i in numPages" :key="i" :page="i" :src="pdfUrl" @error="handleError"></pdf>
    </div>
    <div v-if="coverShow" class="cover"></div>
    <van-loading v-if="loadingShow" type="spinner" vertical size="24px" color="#EB721A" class="custom-loading">loading...</van-loading>
  </div>
</template>

<script>
import { EasyScroller } from "easyscroller";
import pdf from 'vue-pdf'
export default {
  name: "ProductManual",
  components: {
    pdf
  },
  data() {
    const prodUrl = 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual'
    const testUrl = 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual'
    // const prodUrl = 'https://image.engweapp.com/product_manual'
    // const testUrl = 'https://image.engweapp.cn/product_manual'
    const LE20_MANUAL = ['/LE20_DE_MANUAL.pdf', '/LE20_FRE_MANUAL.pdf', '/LE20_HOL_MANUAL.pdf', '/LE20_USA_MANUAL.pdf', '/LE20_EU_MANUAL.pdf', '/LE20_POR_MANUAL.pdf', '/LE20_SPA_MANUAL.pdf', '/LE20_ITA_MANUAL.pdf', '/LE20_POL_MANUAL.pdf']
    const L20_Pro_MANUAL = ['/L20_3.0_Pro_DE_MANUAL.pdf', '/L20_3.0_Pro_FRE_MANUAL.pdf', '/L20_3.0_Pro_HOL_MANUAL.pdf', '/L20_3.0_Pro_USA_MANUAL.pdf', '/L20_3.0_Pro_POR_MANUAL.pdf', '/L20_3.0_Pro_SPA_MANUAL.pdf', '/L20_3.0_Pro_ITA_MANUAL.pdf', '/L20_3.0_Pro_POL_MANUAL.pdf']
    const L20_Boost_MANUAL = ['/L20_3.0_Boost_DE_MANUAL.pdf', '/L20_3.0_Boost_FRE_MANUAL.pdf', '/L20_3.0_Boost_HOL_MANUAL.pdf', '/L20_3.0_Boost_USA_MANUAL.pdf', '/L20_3.0_Boost_POR_MANUAL.pdf', '/L20_3.0_Boost_SPA_MANUAL.pdf', '/L20_3.0_Boost_ITA_MANUAL.pdf', '/L20_3.0_Boost_POL_MANUAL.pdf']
    const N1pro_MANUAL = ['/N1pro_DE_MANUAL.pdf', '/N1pro_FRE_MANUAL.pdf', '/N1pro_HOL_MANUAL.pdf', '/N1pro_USA_MANUAL.pdf', '/N1pro_POR_MANUAL.pdf', '/N1pro_SPA_MANUAL.pdf', '/N1pro_ITA_MANUAL.pdf', '/N1pro_POL_MANUAL.pdf']
    const N1_Air_MANUAL = ['/N1_Air_DE_MANUAL.pdf', '/N1_Air_FRE_MANUAL.pdf', '/N1_Air_HOL_MANUAL.pdf', '/N1_Air_USA_MANUAL.pdf', '/N1_Air_POR_MANUAL.pdf', '/N1_Air_SPA_MANUAL.pdf', '/N1_Air_ITA_MANUAL.pdf', '/N1_Air_POL_MANUAL.pdf']
    const N1_Air_ST_MANUAL = ['/N1_Air_ST_DE_MANUAL.pdf', '/N1_Air_ST_FRE_MANUAL.pdf', '/N1_Air_ST_HOL_MANUAL.pdf', '/N1_Air_ST_USA_MANUAL.pdf', '/N1_Air_ST_POR_MANUAL.pdf', '/N1_Air_ST_SPA_MANUAL.pdf', '/N1_Air_ST_ITA_MANUAL.pdf', '/N1_Air_ST_POL_MANUAL.pdf']
    return {
      lang: 'eu',
      country: 'uk',
      coverShow: false,
      loadingShow: false,
      cargoManualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': prodUrl + LE20_MANUAL[0],   // 德语
        'fr': prodUrl + LE20_MANUAL[1],  // 法语
        'nl': prodUrl + LE20_MANUAL[2],  // 荷兰语
        'en-us': prodUrl + LE20_MANUAL[3],  // 英语(美版)
        'en': prodUrl + LE20_MANUAL[4],   // 英语(欧版)
        'pt': prodUrl + LE20_MANUAL[5],  // 葡萄牙语
        'es': prodUrl + LE20_MANUAL[6],  // 西班牙语
        'it': prodUrl + LE20_MANUAL[7],  // 意大利语
        'pl': prodUrl + LE20_MANUAL[8],  // 波兰语
      } : { // 开发、测试环境
        'de': testUrl + LE20_MANUAL[0],   // 德语
        'fr': testUrl + LE20_MANUAL[1],  // 法语
        'nl': testUrl + LE20_MANUAL[2],  // 荷兰语
        'en-us': testUrl + LE20_MANUAL[3],  // 英语(美版)
        'en': testUrl + LE20_MANUAL[4],   // 英语(欧版)
        'pt': testUrl + LE20_MANUAL[5],  // 葡萄牙语
        'es': testUrl + LE20_MANUAL[6],  // 西班牙语
        'it': testUrl + LE20_MANUAL[7],  // 意大利语
        'pl': testUrl + LE20_MANUAL[8],  // 波兰语
      },
      l20ProManualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': prodUrl + L20_Pro_MANUAL[0],  // 德语
        'fr': prodUrl + L20_Pro_MANUAL[1],  // 法语
        'nl': prodUrl + L20_Pro_MANUAL[2],  // 荷兰语
        'en': prodUrl + L20_Pro_MANUAL[3],  // 英语(美版)
        'pt': prodUrl + L20_Pro_MANUAL[4],  // 葡萄牙语
        'es': prodUrl + L20_Pro_MANUAL[5],  // 西班牙语
        'it': prodUrl + L20_Pro_MANUAL[6],  // 意大利语
        'pl': prodUrl + L20_Pro_MANUAL[7],  // 波兰语
      } : { // 开发、测试环境
        'de': testUrl + L20_Pro_MANUAL[0],  // 德语
        'fr': testUrl + L20_Pro_MANUAL[1],  // 法语
        'nl': testUrl + L20_Pro_MANUAL[2],  // 荷兰语
        'en': testUrl + L20_Pro_MANUAL[3],  // 英语(美版)
        'pt': testUrl + L20_Pro_MANUAL[4],  // 葡萄牙语
        'es': testUrl + L20_Pro_MANUAL[5],  // 西班牙语
        'it': testUrl + L20_Pro_MANUAL[6],  // 意大利语
        'pl': testUrl + L20_Pro_MANUAL[7],  // 波兰语
      },
      l20BoostManualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': prodUrl + L20_Boost_MANUAL[0],  
        'fr': prodUrl + L20_Boost_MANUAL[1],  
        'nl': prodUrl + L20_Boost_MANUAL[2],  
        'en': prodUrl + L20_Boost_MANUAL[3],  
        'pt': prodUrl + L20_Boost_MANUAL[4],  
        'es': prodUrl + L20_Boost_MANUAL[5],  
        'it': prodUrl + L20_Boost_MANUAL[6],  
        'pl': prodUrl + L20_Boost_MANUAL[7],  
      } : { // 开发、测试环境
        'de': testUrl + L20_Boost_MANUAL[0],  
        'fr': testUrl + L20_Boost_MANUAL[1],  
        'nl': testUrl + L20_Boost_MANUAL[2],  
        'en': testUrl + L20_Boost_MANUAL[3],  
        'pt': testUrl + L20_Boost_MANUAL[4],  
        'es': testUrl + L20_Boost_MANUAL[5],  
        'it': testUrl + L20_Boost_MANUAL[6],  
        'pl': testUrl + L20_Boost_MANUAL[7],  
      },
      n1ProManualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': prodUrl + N1pro_MANUAL[0],  
        'fr': prodUrl + N1pro_MANUAL[1],  
        'nl': prodUrl + N1pro_MANUAL[2],  
        'en': prodUrl + N1pro_MANUAL[3],  
        'pt': prodUrl + N1pro_MANUAL[4],  
        'es': prodUrl + N1pro_MANUAL[5],  
        'it': prodUrl + N1pro_MANUAL[6],  
        'pl': prodUrl + N1pro_MANUAL[7],  
      } : { // 开发、测试环境
        'de': testUrl + N1pro_MANUAL[0],  
        'fr': testUrl + N1pro_MANUAL[1],  
        'nl': testUrl + N1pro_MANUAL[2],  
        'en': testUrl + N1pro_MANUAL[3],  
        'pt': testUrl + N1pro_MANUAL[4],  
        'es': testUrl + N1pro_MANUAL[5],  
        'it': testUrl + N1pro_MANUAL[6],  
        'pl': testUrl + N1pro_MANUAL[7],  
      },
      n1AirManualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': prodUrl + N1_Air_MANUAL[0],  
        'fr': prodUrl + N1_Air_MANUAL[1],  
        'nl': prodUrl + N1_Air_MANUAL[2],  
        'en': prodUrl + N1_Air_MANUAL[3],  
        'pt': prodUrl + N1_Air_MANUAL[4],  
        'es': prodUrl + N1_Air_MANUAL[5],  
        'it': prodUrl + N1_Air_MANUAL[6],  
        'pl': prodUrl + N1_Air_MANUAL[7],  
      } : { // 开发、测试环境
        'de': testUrl + N1_Air_MANUAL[0],  
        'fr': testUrl + N1_Air_MANUAL[1],  
        'nl': testUrl + N1_Air_MANUAL[2],  
        'en': testUrl + N1_Air_MANUAL[3],  
        'pt': testUrl + N1_Air_MANUAL[4],  
        'es': testUrl + N1_Air_MANUAL[5],  
        'it': testUrl + N1_Air_MANUAL[6],  
        'pl': testUrl + N1_Air_MANUAL[7],  
      },
      n1AirSTManualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': prodUrl + N1_Air_ST_MANUAL[0],  
        'fr': prodUrl + N1_Air_ST_MANUAL[1],  
        'nl': prodUrl + N1_Air_ST_MANUAL[2],  
        'en': prodUrl + N1_Air_ST_MANUAL[3],  
        'pt': prodUrl + N1_Air_ST_MANUAL[4],  
        'es': prodUrl + N1_Air_ST_MANUAL[5],  
        'it': prodUrl + N1_Air_ST_MANUAL[6],  
        'pl': prodUrl + N1_Air_ST_MANUAL[7],  
      } : { // 开发、测试环境
        'de': testUrl + N1_Air_ST_MANUAL[0],  
        'fr': testUrl + N1_Air_ST_MANUAL[1],  
        'nl': testUrl + N1_Air_ST_MANUAL[2],  
        'en': testUrl + N1_Air_ST_MANUAL[3],  
        'pt': testUrl + N1_Air_ST_MANUAL[4],  
        'es': testUrl + N1_Air_ST_MANUAL[5],  
        'it': testUrl + N1_Air_ST_MANUAL[6],  
        'pl': testUrl + N1_Air_ST_MANUAL[7],  
      },
      scroller: null, // 获取手势操作项
      pdfUrl: null,
      numPages: null
    };
  },
  created() {
    const params = new URLSearchParams(window.location.search)
    this.lang = params.get('lang'); 
    this.country = params.get('country'); 
  },
  methods: {
    // 点击cargo图片
    cargoImgClick() {
      this.handleClick(this.cargoManualMap);
    },
    // // 点击l20Pro图片
    // l20ProImgClick() {
    //   this.handleClick(this.l20ProManualMap);
    // },
    // // 点击l20Boost图片
    // l20BoostImgClick() {
    //   this.handleClick(this.l20BoostManualMap);
    // },
    // // 点击n1Pro图片
    // n1ProImgClick() {
    //   this.handleClick(this.n1ProManualMap);
    // },
    // // 点击n1Air图片
    // n1AirImgClick() {
    //   this.handleClick(this.n1AirManualMap);
    // },
    // // 点击n1AirST图片
    // n1AirSTImgClick() {
    //   this.handleClick(this.n1AirSTManualMap);
    // },
    // 处理点击跳转
    handleClick(productMap) {
      this.loadingShow = true;
      this.coverShow = true;
      if (productMap[this.lang]) {
        if (this.country === 'uk') { 
          this.skipPdf(productMap, 'en');
        } else if (this.country === 'us') {
          window.location.href = productMap['en-us'];
          this.skipPdf(productMap, 'en-us');
        } else {
          this.skipPdf(productMap, this.lang);
        }
      } else { // 默认跳转英文欧版
        this.skipPdf(productMap, 'en');
      }
    },
    // 跳转pdf
    async skipPdf(pdfMap, lang) {
      this.pdfUrl = pdfMap[lang];
      await this.getTotal()
      this.zoomBox()
    },
    // 获取pdf总页数
    getTotal() {
      // 需要使用下述方法的返回值作为url
      this.pdfUrl = pdf.createLoadingTask(this.pdfUrl)
      // 获取页码
      this.pdfUrl.promise.then(pdf => {
        this.numPages = pdf._pdfInfo.numPages;
        this.loadingShow = false;
        this.coverShow = false;
      }).catch(error => {
        console.error('pdf加载失败', error)
      })
    },
    // 配置div的放大缩小
    zoomBox() {
      const ele = document.querySelector("#zoomBox");
      this.scroller = new EasyScroller(ele, {
        scrollingX: true,
        scrollingY: true,
        zooming: true,
        minZoom: 1,   // 最小缩放
        maxZoom: 5,   // 最大缩放
        zoomLevel: 1, // 初始值缩放
        bouncing: false, // 是否开启回弹效果
      });
    },
    // pdf加载失败
    handleError(error) {
      console.error('pdf加载失败:', error)
    },
  },
  beforeDestroy() {
    this.scroller.destroy(); // 销毁
  },
};
</script>

<style scoped lang="scss">
.product-manual{
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  overflow: auto;
  -webkit-overflow-scrolling: touch;  
  &-list{
    padding: 20px;
    .product-img{
      width: 100%;
      height: 100%;
    }
  }
  &-pdf{
    top: 0; 
    left: 0; 
    width: 100%; 
    // height: 100%; 
    position: fixed; 
    z-index: 9999;
    overflow: scroll;
    -webkit-overflow-scrolling : touch;
  }
  .cover{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
  .custom-loading{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }
}
</style>

