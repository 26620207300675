import Vue from 'vue'

import App from './App'
// import store from './store'
import router from './router'

import 'amfe-flexible'
import "@/assets/font/text.css";
// import VConsole from 'vconsole'
// const vConsole = new VConsole();
// Vue.use(vConsole)

import { Loading } from 'vant';
import 'vant/lib/index.css'; // 全局引入样式
Vue.use(Loading);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

new Vue({
  el: '#app',
  router,
  // store,
  render: h => h(App)
})
