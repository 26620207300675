var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upgrade" }, [
    _c("div", { staticClass: "upgrade-container" }, [
      _c("div", { staticClass: "upgrade-content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("ENGWE")]),
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("../assets/images/logo.png"), alt: "" },
        }),
        _c(
          "div",
          { staticClass: "install-btn", on: { click: _vm.installClick } },
          [_vm._v("Install")]
        ),
        _c("p", { staticClass: "tip" }, [
          _vm._v(
            "NOTE: If the redirection is unsuccessful, please go to the Google Play Store to download the update."
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }