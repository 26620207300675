var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manual" }, [
    _c("div", { staticClass: "user-manual-list" }, [
      _c(
        "div",
        { staticClass: "user-manual-item", on: { click: _vm.euClick } },
        [
          _c("img", {
            staticClass: "bike-img",
            attrs: { src: require("../assets/images/LE20-EU.png"), alt: "" },
          }),
          _c("div", { staticClass: "bike-text" }, [
            _vm._v("LE20 EU version Unboxing & Assembly"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "user-manual-item", on: { click: _vm.usClick } },
        [
          _c("img", {
            staticClass: "bike-img",
            attrs: { src: require("../assets/images/LE20-US.png"), alt: "" },
          }),
          _c("div", { staticClass: "bike-text" }, [
            _vm._v("LE20 US version Unboxing & Assembly"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "user-manual-item", on: { click: _vm.kphClick } },
        [
          _c("img", {
            staticClass: "bike-img",
            attrs: { src: require("../assets/images/LE20-KPH.png"), alt: "" },
          }),
          _c("div", { staticClass: "bike-text" }, [
            _vm._v("LE20 EU KPH & MPH switch"),
          ]),
        ]
      ),
    ]),
    _vm.euVideoShow
      ? _c(
          "div",
          {
            staticClass: "iframe-container",
            on: {
              click: function ($event) {
                _vm.euVideoShow = false
              },
            },
          },
          [
            _c("iframe", {
              staticClass: "iframe",
              attrs: {
                src: _vm.euVideoUrl,
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.usVideoShow
      ? _c(
          "div",
          {
            staticClass: "iframe-container",
            on: {
              click: function ($event) {
                _vm.usVideoShow = false
              },
            },
          },
          [
            _c("iframe", {
              staticClass: "iframe",
              attrs: {
                src: _vm.usVideoUrl,
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.kphVideoShow
      ? _c(
          "div",
          {
            staticClass: "iframe-container",
            on: {
              click: function ($event) {
                _vm.kphVideoShow = false
              },
            },
          },
          [
            _c("iframe", {
              staticClass: "iframe",
              attrs: {
                src: _vm.kphVideoUrl,
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }