var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "smart_service" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "service_footer" }, [
      _c("div", { staticClass: "service_footer_title" }, [_vm._v("Territory")]),
      _c("div", { staticClass: "service_footer_content" }, [
        _vm._v("The service works in "),
        _c("a", { staticClass: "link", on: { click: _vm.handleClick } }, [
          _vm._v("Europe"),
          _c("i", { staticClass: "tip_icon" }),
        ]),
        _vm._v(
          "Further countries will be added over time. Smart service features are not available in other regions. Data recording stops when the bike moves to a region outside the usage regions; the map will then show the last detected location."
        ),
      ]),
    ]),
    _vm.coverShow
      ? _c("div", {
          staticClass: "cover",
          on: {
            click: function ($event) {
              _vm.coverShow = false
              _vm.popupShow = false
            },
          },
        })
      : _vm._e(),
    _vm.popupShow
      ? _c("div", { staticClass: "country_popup" }, [
          _c("div", { staticClass: "country_popup_title" }, [_vm._v("Europe")]),
          _c("div", { staticClass: "country_popup_content" }, [
            _vm._v(
              "Austria,Belgium,Bulgaria,Croatia,Cyprus,Czech Republic,Denmark,Estonia,Finland,France,Germany,Gibraltar,Greece,Hungary,Iceland,Italy,Latvia,Liechtenstein,Lithuania,Luxembourg,Malta,Netherlands,Norway,Poland,Portugal,Romania,Slovak Republic,Slovenia,Spain,Sweden,United Kingdom."
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "service_header" }, [
      _c("div", { staticClass: "service_header_bottom" }, [
        _c("div", { staticClass: "service_header_bottom_left" }, [
          _vm._v("Status"),
        ]),
        _c("div", { staticClass: "service_header_bottom_right" }, [
          _vm._v("normal"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "service_content" }, [
      _c("div", { staticClass: "service_content_title" }, [
        _c("img", {
          staticClass: "title_img",
          attrs: { src: require("../assets/images/serviceTitle.png"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "service_content_top" }, [
        _vm._v(
          " Enjoy one year of free bike tracking and digital alarm services with your first use. After the service period ends, you can choose to renew and continue using the service "
        ),
      ]),
      _c("div", { staticClass: "service_content_center" }, [
        _c("div", { staticClass: "service_content_center_wrapper" }, [
          _c("div", { staticClass: "service_content_center_tracking" }, [
            _c("div", { staticClass: "service_content_center_tracking_img" }),
            _c("div", { staticClass: "service_content_center_text" }, [
              _vm._v(
                "GPS Tracking – see where your bike is always through the ENGWE App."
              ),
            ]),
          ]),
          _c("div", { staticClass: "service_content_center_lock" }, [
            _c("div", { staticClass: "service_content_center_lock_img" }),
            _c("div", { staticClass: "service_content_center_text" }, [
              _vm._v("Digital Bike Lock – secure your bike digitally."),
            ]),
          ]),
          _c("div", { staticClass: "service_content_center_detector" }, [
            _c("div", { staticClass: "service_content_center_detector_img" }),
            _c("div", { staticClass: "service_content_center_text" }, [
              _vm._v(
                "Motion Detector – be alerted whenever your locked bike moves."
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "service_content_bottom" }, [
        _vm._v(
          " Unlock the power of your bikes through the ENGWE App. Be safe and secure Europe-wide. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }