<template>
  <div class="upgrade">
    <div class="upgrade-container">
      <div class="upgrade-content">
        <div class="title">ENGWE</div>
        <!-- <div class="explain">ENGWE Labs</div> -->
        <img class="logo" src="../assets/images/logo.png" alt="" />
        <div class="install-btn" @click="installClick">Install</div>
        <p class="tip">NOTE: If the redirection is unsuccessful, please go to the Google Play Store to download the update.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserManual",
  data() {
    return {
     
    };
  },
  methods: {
    installClick(){
      window.location.href = 'https://play.google.com/store/apps/details?id=com.engwe.app'
    }
  },
};
</script>

<style scoped lang="scss">
.upgrade{
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  &-container{
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -90%);
  }
  &-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .explain{
      font-size: 12px;
      color: #01875f;
      padding: 10px 0 20px;
    }
    .logo{
      width: 120px;
      height: 120px;
      border-radius: 20px;
      display: block;
    }
  }
  .install-btn{
    width: 50%;
    height: 36px;
    line-height: 36px;
    background: #eb721a;
    text-align: center;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    margin-top: 40px;
  }
  .tip{
    font-size: 12px;
    color: #000;
    padding-top: 20px;
    margin: 0;
  }
}
</style>

