<template>
  <div class="product-manual">
    <div class="product-manual-list">
      <img src="../assets/images/cargo.png" class="product-img" alt="" @click="imgClick" />
    </div>
    <div id="zoomBox" class="product-manual-pdf" v-if="pdfUrl">
      <pdf ref="pdf" v-for="i in numPages" :key="i" :page="i" :src="pdfUrl" @error="handleError"></pdf>
    </div>
  </div>
</template>

<script>
import { EasyScroller } from "easyscroller";
import pdf from 'vue-pdf'
export default {
  name: "ProductManual",
  components: {
    pdf
  },
  data() {
    return {
      lang: 'eu',
      country: 'uk',
      manualMap: process.env.NODE_ENV === 'production' ? { // 生产环境
        'de': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_DE_MANUAL.pdf',   // 德语
        'fr': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_FRE_MANUAL.pdf',  // 法语
        'nl': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_HOL_MANUAL.pdf',  // 荷兰语
        'en-us': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_USA_MANUAL.pdf',  // 英语(美版)
        'en': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_EU_MANUAL.pdf',   // 英语(欧版)
        'pt': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_POR_MANUAL.pdf',  // 葡萄牙语
        'es': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_SPA_MANUAL.pdf',  // 西班牙语
        'it': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_ITA_MANUAL.pdf',  // 意大利语
        'pl': 'https://engwe.oss-eu-central-1.aliyuncs.com/product_manual/LE20_POL_MANUAL.pdf',  // 波兰语
      } : { // 开发、测试环境
        'de': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_DE_MANUAL.pdf',   // 德语
        'fr': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_FRE_MANUAL.pdf',  // 法语
        'nl': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_HOL_MANUAL.pdf',  // 荷兰语
        'en-us': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_USA_MANUAL.pdf',  // 英语(美版)
        'en': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_EU_MANUAL.pdf',   // 英语(欧版)
        'pt': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_POR_MANUAL.pdf',  // 葡萄牙语
        'es': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_SPA_MANUAL.pdf',  // 西班牙语
        'it': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_ITA_MANUAL.pdf',  // 意大利语
        'pl': 'https://engweapp.oss-cn-guangzhou.aliyuncs.com/product_manual/LE20_POL_MANUAL.pdf',  // 波兰语
      },
      scroller: null, // 获取手势操作项
      pdfUrl: null,
      numPages: null
    };
  },
  created() {
    const params = new URLSearchParams(window.location.search)
    this.lang = params.get('lang'); 
    this.country = params.get('country'); 
    console.log('this.lang===', this.lang)
    console.log('this.country===', this.country)
  },
  methods: {
    // 点击图片
    imgClick() {
      if (this.manualMap[this.lang]) {
        if (this.country === 'uk') { 
          this.skipPdf('en');
        } else if (this.country === 'us') {
          window.location.href = this.manualMap['en-us'];
          this.skipPdf('en-us');
        } else {
          this.skipPdf(this.lang);
        }
      } else { // 默认跳转英文欧版
        this.skipPdf('en');
      }
    },
    // 跳转pdf
    async skipPdf(lang) {
      this.pdfUrl = this.manualMap[lang];
      console.log('this.pdfUrl===', this.pdfUrl)
      await this.getTotal()
      this.zoomBox()
      // window.location.href = pdfUrl;
    },
    // 配置div的放大缩小
    zoomBox() {
      const ele = document.querySelector("#zoomBox");
      this.scroller = new EasyScroller(ele, {
        scrollingX: true,
        scrollingY: true,
        zooming: true,
        minZoom: 1,   // 最小缩放
        maxZoom: 5,   // 最大缩放
        zoomLevel: 1, // 初始值缩放
        bouncing: false, // 是否开启回弹效果
      });
    },
    // 获取pdf总页数
    getTotal() {
      // 需要使用下述方法的返回值作为url
      this.pdfUrl = pdf.createLoadingTask(this.pdfUrl)
      // 获取页码
      this.pdfUrl.promise.then(pdf => {
        // console.log('pdf===', pdf)
        this.numPages = pdf._pdfInfo.numPages;
      }).catch(error => {
        console.error('pdf加载失败', error)
      })
    },
    // pdf加载失败
    handleError(error) {
      console.error('PDF加载失败:', error)
    },
  },
  beforeDestroy() {
    this.scroller.destroy(); // 销毁
  },
};
</script>

<style scoped lang="scss">
.product-manual{
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  &-list{
    padding: 20px;
    .product-img{
      width: 100%;
      height: 100%;
    }
  }
  &-pdf{
    top: 0; 
    left: 0; 
    width: 100%; 
    // height: 100%; 
    position: fixed; 
    z-index: 9999;
    overflow: scroll;
    -webkit-overflow-scrolling : touch;
  }
}
</style>

