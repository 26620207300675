<template>
  <div class="app-container home">
    <h1 class="title">系统通知 Ebike通知</h1>
  </div>
</template>

<script>

export default {
  name: "Index",
  data() {
    return {
      
    };
  },
  methods: {
   
  },
};
</script>

<style scoped lang="scss">
.title{
  font-size: 20px;
}
</style>

