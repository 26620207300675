<template>
  <div id="paypalContainer"></div>
</template>

<script>
export default {
  name: 'PayPalPayment',
  data() {
    return {
      containerId: '#paypalContainer',
      clientID: 'ASyhA3RZP6lKMIbPrGxmGSUTiDTmDMG7QOEAcrwCSFn8HmBgXu8g1t5G6lDYivR5nOJyKLpYfoGhnfDd',
      currency: 'USD',
      amount: '60.00',
      buttonStyle: {
        layout: 'vertical',
        color: 'gold',
        shape: 'pill',
        label: 'paypal',
        tagline: false
      }
    };
  },
  mounted() {
    this.renderPayPalButton();
  },
  methods: {
    renderPayPalButton() {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.clientID}&disable-funding=credit,card,paylater`;
      script.addEventListener('load', () => {
        window.paypal.Buttons({
          style: this.buttonStyle,
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: this.amount,
                  currency_code: this.currency
                }
              }]
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((order) => {
              console.log('Payment successful:', order);
              // 处理支付成功后的逻辑，例如跳转页面或更新订单状态
            });
          },
          paymentError: (error) => {
            console.error('Payment failed:', error);
          }
        }).render(this.containerId);
      });
      document.body.appendChild(script);
    }
  }
};
</script>
