var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-manual" }, [
    _c("div", { staticClass: "product-manual-list" }, [
      _c("img", {
        staticClass: "product-img",
        attrs: { src: require("../assets/images/cargo.png"), alt: "" },
        on: { click: _vm.imgClick },
      }),
    ]),
    _vm.pdfUrl
      ? _c(
          "div",
          { staticClass: "product-manual-pdf", attrs: { id: "zoomBox" } },
          _vm._l(_vm.numPages, function (i) {
            return _c("pdf", {
              key: i,
              ref: "pdf",
              refInFor: true,
              attrs: { page: i, src: _vm.pdfUrl },
              on: { error: _vm.handleError },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }