<template>
  <div class="smart_service">
    <div class="service_header">
      <!-- <div class="service_header_top">
        <div class="service_header_top_left">
          <img src="../assets/images/card.png" alt="" class="card_img">
        </div>
        <div class="service_header_top_right">
          <div class="service_header_top_right_vaildDate">Valid until ：Sep 30,2025</div>
          <div class="service_header_top_right_activeDate">Activation date: Sep 30, 2024</div>
        </div>
      </div> -->
      <div class="service_header_bottom">
        <div class="service_header_bottom_left">Status</div>
        <div class="service_header_bottom_right">normal</div>
      </div>
    </div>
    <div class="service_content">
      <div class="service_content_title">
        <img src="../assets/images/serviceTitle.png" alt="" class="title_img">
      </div>
      <div class="service_content_top">
        Enjoy one year of free bike tracking and digital alarm services with your first use. After the service period ends, you can choose to renew and continue using the service
      </div>
      <div class="service_content_center">
        <div class="service_content_center_wrapper">
          <div class="service_content_center_tracking">
            <div class="service_content_center_tracking_img"></div>
            <div class="service_content_center_text">GPS Tracking – see where your bike is always through the ENGWE App.</div>
          </div>
          <div class="service_content_center_lock">
            <div class="service_content_center_lock_img"></div>
            <div class="service_content_center_text">Digital Bike Lock – secure your bike digitally.</div>
          </div>
          <div class="service_content_center_detector">
            <div class="service_content_center_detector_img"></div>
            <div class="service_content_center_text">Motion Detector – be alerted whenever your locked bike moves.</div>
          </div>
        </div>
      </div>
      <div class="service_content_bottom">
        Unlock the power of your bikes through the ENGWE App. Be safe and secure Europe-wide.
      </div>
    </div>
    <div class="service_footer">
      <div class="service_footer_title">Territory</div>
      <div class="service_footer_content">The service works in <a class="link" @click="handleClick">Europe<i class="tip_icon"></i></a>Further countries will be added over time. Smart service features are not available in other regions. Data recording stops when the bike moves to a region outside the usage regions; the map will then show the last detected location.</div>
    </div>
    <div class="cover" v-if="coverShow" @click="coverShow = false;popupShow = false"></div>
    <div class="country_popup" v-if="popupShow">
      <div class="country_popup_title">Europe</div>
      <div class="country_popup_content">Austria,Belgium,Bulgaria,Croatia,Cyprus,Czech Republic,Denmark,Estonia,Finland,France,Germany,Gibraltar,Greece,Hungary,Iceland,Italy,Latvia,Liechtenstein,Lithuania,Luxembourg,Malta,Netherlands,Norway,Poland,Portugal,Romania,Slovak Republic,Slovenia,Spain,Sweden,United Kingdom.</div>
    </div>
  </div>
</template>
 
<script>
 
export default {
  name: 'SmartService',
  data() {
    return {
      coverShow: false,
      popupShow: false
    }
  },
  methods: {
    handleClick() {
      this.coverShow = true;
      this.popupShow = true;
    }
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.smart_service{
  width: 100%;
  height: 100%;
  background: #F9F9F9;
  padding: 12px 0;
  .service_header{
    padding: 0 20px;
    background: #fff;
    &_top{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 0;
      &_left{
        .card_img{
          width: 80px;
          height: 80px;
          margin-right: 12px;
          // background: url("../assets/images/card.png") no-repeat center;
          // background-size: 100% 100%;
        }
      }
      &_right{
        &_vaildDate{
          font-weight: bold;
          font-size: 16px;
          color: #101010;
          margin-bottom: 12px;
        }
        &_activeDate{
          font-size: 13px;
          color: rgba(49,49,49,0.65)
        }
      }
    }
    &_bottom{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      border-top: 1px solid #E5E5E5;
      &_left{
        font-size: 16px;
        color: rgba(0,0,0,0.9); 
      }
      &_right{
        font-size: 15px;
        color: #ED731B;
      }
    }
  }
  .service_content{
    margin-top: 12px;
    padding: 0 20px;
    background: #fff;
    &_title{
      display: flex;
      justify-content: center;
      padding: 16px 0 0;
      .title_img{
        width: 280px;
        height: 50px;
      }
    }
    &_top{
      font-size: 12px;
      color: rgba(49,49,49,0.85);
      text-align: center;
      margin-bottom: 10px;
    }
    &_center{
      width: 335px;
      height: 168px;
      background: url("../assets/images/serviceBg.png") no-repeat center;
      background-size: 100% 100%;
      &_wrapper{
        padding: 20px 12px;
      }
      &_tracking{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        &_img{
          width: 32px;
          height: 32px;
          background: url("../assets/images/tracking.png") no-repeat center;
          background-size: 100% 100%;
          flex-shrink: 0;
        }
      }
      &_lock{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        &_img{
          width: 32px;
          height: 32px;
          background: url("../assets/images/lock.png") no-repeat center;
          background-size: 100% 100%;
          flex-shrink: 0;
        }
      }
      &_detector{
        display: flex;
        flex-direction: row;
        align-items: center;
        &_img{
          width: 32px;
          height: 32px;
          background: url("../assets/images/detector.png") no-repeat center;
          background-size: 100% 100%;
          flex-shrink: 0;
        }
      }
      &_text{
        font-size: 13px;
        color: #9B571F; 
        margin-left: 12px;
      }
    }
    &_bottom{
      font-size: 12px;
      color: rgba(49,49,49,0.65);
      padding: 16px 0;
      text-align: center;
    }
  }
  .service_footer{
    padding: 0 20px;
    &_title{
      font-size: 16px;
      color: rgba(0,0,0,0.9);
      padding: 16px 0 10px;
    }
    &_content{
      font-size: 12px;
      color: #313131;
      line-height: 16px;
      .link{
        color: #ED731B;
        text-decoration: underline;
      }
      .tip_icon{
        display: inline-block;
        width: 12px;
        height: 13px;
        background: url("../assets/images/tip.png") no-repeat center;
        background-size: 100% 100%;
        position: relative;
        top: 2px;
        margin: 0 4px;
      }
    }
  }
  .cover{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 999;
  }
  .country_popup{
    width: 100%;
    height: 255px;
    background: #fff;
    border-radius: 8px 8px 0 0;
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-sizing: border-box;
    &_title{
      font-size: 16px;
      color: rgba(0,0,0,0.9);
      font-weight: bold;
      margin-bottom: 10px;
    }
    &_content{
      font-size: 15px;
      color: #313131;
      word-wrap: break-word;
      line-height: 24px;
    }
  }
}
</style>