<template>
  <div class="user-manual">
    <div class="user-manual-list">
      <div class="user-manual-item" @click="euClick">
        <img src="../assets/images/LE20-EU.png" class="bike-img" alt="" />
        <div class="bike-text">LE20 EU version Unboxing & Assembly</div>
      </div>
      <div class="user-manual-item" @click="usClick">
        <img src="../assets/images/LE20-US.png" class="bike-img" alt="" />
        <div class="bike-text">LE20 US version Unboxing & Assembly</div>
      </div>
      <div class="user-manual-item" @click="kphClick">
        <img src="../assets/images/LE20-KPH.png" class="bike-img" alt="" />
        <div class="bike-text">LE20 EU KPH & MPH switch</div>
      </div>
    </div>
    <div class="iframe-container" v-if="euVideoShow" @click="euVideoShow = false">
      <iframe class="iframe" :src="euVideoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="iframe-container" v-if="usVideoShow" @click="usVideoShow = false">
      <iframe class="iframe" :src="usVideoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="iframe-container" v-if="kphVideoShow" @click="kphVideoShow = false">
      <iframe class="iframe" :src="kphVideoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserManual",
  data() {
    return {
      euVideoShow: false,
      usVideoShow: false,
      kphVideoShow: false,
      euVideoUrl: 'https://www.youtube.com/embed/ZecLX_-VcnY?autoplay=1',   // 开箱视频（欧版）
      usVideoUrl: 'https://www.youtube.com/embed/lnoyi_9ExNk?autoplay=1',   // 开箱视频（美版）
      kphVideoUrl: 'https://www.youtube.com/embed/6aummNsysn4?autoplay=1',  // 公英里切换视频
    };
  },
  methods: {
    euClick() {
      this.euVideoShow = true;
    },
    usClick() {
      this.usVideoShow = true;
    },
    kphClick() {
      this.kphVideoShow = true;
    }
  },
};
</script>

<style scoped lang="scss">
.user-manual{
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  &-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
  &-item{
    width: 47.5%;
    background: #fff;
    margin-bottom: 20px;
    .bike-img{
      width: 100%;
      // height: 100%;
    }
    .bike-text{
      font-family: "SFZY";
      font-size: 12px;
      font-weight: bold;
      // color: #000000;
      padding: 0px 8px 16px;
    }
  }
  .iframe-container{
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    position: fixed; 
    background: #000; 
    z-index: 9999;
    .iframe{
      width: 100%;
      height: 100%;
    }
  }
}
</style>

