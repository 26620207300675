import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/index',
    component: () => import('@/views/index'),
    hidden: true
  },
  {
    path: '/productManual',
    component: () => import('@/views/productManual'),
    hidden: true
  },
  {
    path: '/userManual',
    component: () => import('@/views/userManual'),
    hidden: true
  },
]


export default new Router({
  base: process.env.VUE_APP_CONTEXT_PATH,
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
