<template>
  <div class="qr_code">
    <div class="app_bg" onclick="goDownload(true)"></div>
  </div>
</template>
 
<script>
 
export default {
  name: 'QrCode',
  methods: {
    isWeixin(){
      let ua = navigator.userAgent.toLowerCase()
      return ua.indexOf('micromessenger') !== -1
    },
    isIos(){
      let ua = navigator.userAgent.toLowerCase()
      return ua.indexOf('iphone') > -1 || ua.indexOf('mac os') > -1
    },
    goDownload(wxClick) {
      if(this.isIos()) {
        window.location.href = 'https://apps.apple.com/us/app/engwe/id6479933525'
      } else if (this.isWeixin()) { // 微信浏览器时点击弹出提示，其他浏览器直接触发下载
        if(wxClick) alert("请点击右上角按钮, 选择使用浏览器打开")
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.engwe.app'
      }
    }
  },
  mounted() {
    this.goDownload()
  }
};
</script>
<style lang="scss" scoped>
.qr_code{
  width: 100%;
  height: 100%;
}
.app_bg{
  width: 100%;
  height: 100%;
  // background-image: url("../assets/images/cargo.png");
  // background-size: 100% 100%;
  // background-position: center;
}
</style>